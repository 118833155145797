<template>
    <div class="d-contents" v-if="uiStore.isMobile" @click.stop.prevent="localOpen=true;">
        <slot/>
    </div>
    <popover v-else :title="title" :placement="popoverPlacement" :forcePlacement="forcePopoverPlacement" @show="localOpen=true; $emit('show')" @preShow="$emit('preShow')" @hide="localOpen=false; $emit('hide')" @preHide="$emit('preHide')" :open="localOpen" :outClickClose="addCloseButton"  :noPadding="noPadding">
        <template #default>
            <slot/>
        </template>
        <template #content>
            <slot name="content"/>
        </template>
        <template v-if="addCloseButton" #close>
            <div class="clickable position-absolute bg-light rounded-circle p-1" style="top:-10px; right:-10px; font-size: larger; line-height: 0px;"  @click.stop="localOpen=false; $emit('close')">
                <i class="fas fa-times-circle text-danger"></i>
            </div>
        </template>
    </popover>
    
    <peek v-if="uiStore.isMobile" :size="peekSize" :autoSize="peekAutoSize" :open="localOpen" @open="localOpen=true; $emit('show')" @preOpen="$emit('preShow')" @close="localOpen=false; $emit('hide');" @preClose="$emit('preHide')" placement="bottom" :overlay="true"  :noPadding="noPadding">
        <template #header>
            {{ title }}
        </template>
        <template #default>
            <slot name="content"/>
            <div class="mt-2 text-center" v-if="addCloseButton">
                <ui-button size="sm" color="secondary" @click="localOpen=false; $emit('close')">Close</ui-button>
            </div>
        </template>
    </peek>
</template>

<script type="text/javascript">
    import { UseUIStore } from '@/store/UI.js';

    import ButtonComponents from '@/components/UI/Button/index.vue';
    import Popover from '@/components/Action/Popover.vue';
    import Peek from '@/components/Action/Peek.vue';

    export default {
        components: {
            Popover,
            Peek,
            ...ButtonComponents,
        },

        setup() {
            return {
                uiStore: UseUIStore(),
            };
        },

        data() {
            return {
                localOpen: false,
            };
        },

        created() {
            this.localOpen = this.open;
        },

        watch: {
            open(newVal) {
                if ((newVal === null) || (newVal == this.localOpen)) return;
                this.localOpen = newVal;
            }
        },

        emits: ['show', 'hide', 'preShow', 'preHide', 'close'],

        props : {
            open: {
                type: Boolean,
                default: null,
            },

            title: {
                type: String,
            },

            peekSize: {
                type: [Number, Object]
            },

            peekAutoSize: {
                type: Boolean,
                default: false,
            },

            addCloseButton: {
                type: Boolean,
                default: false,
            },

            popoverPlacement: {
                type: String,
                default: 'auto',
            },

            forcePopoverPlacement: {
                type: Boolean,
                default: false,
            },

            noPadding: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>