<template>
    <form @submit.prevent="processForm" ref="form">
        <div class="login-form" :class="boxCssClass" style="min-width:280px;">
            <alert v-if="showLogout" color="danger">
                You are logged in to your account. Click the "Logout" button below to create a new account.
            </alert>

            <h3 class="p-4 pb-0">Create an Account</h3>

            <div class="p-4">
                <container>
                    <row>
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="firstNameValid">
                                <form-label>First Name:</form-label>
                                <form-input name="first-name" v-model="firstName" @input="validateForm" autocomplete="given-name"/>
                            </form-group>
                        </column>
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="lastNameValid">
                                <form-label>Last Name:</form-label>
                                <form-input name="last-name" v-model="lastName" @input="validateForm" autocomplete="family-name"/>
                            </form-group>
                        </column>
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="emailValid" :feedback="emailFeedback">
                                <form-label>Email:</form-label>
                                <form-input name="email" v-model="email" @input="validateForm"/>
                            </form-group>
                        </column>
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="phoneValid">
                                <form-label>
                                    Phone:
                                    <tooltip :title="internationalNumbersTitle">
                                        <sup><i class="fas fa-globe-americas"></i></sup>
                                    </tooltip>
                                </form-label>
                                <form-input name="tel" v-model="phone" @input="validateForm"/>
                            </form-group>
                        </column>
                        <column :lg="16" v-if="!quickSignup">
                            <form-address v-model="address" :valid="addressValid" @input="validateForm"/>
                        </column>
                    </row>

                    <row class="mt-3">
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="passwordValid" :feedback="passwordFeedback">
                                <form-label>Password:</form-label>
                                <form-input name="password" :type="passwordType" v-model="password" @input="validateForm" autocomplete="new-password" />

                                <form-text class="clickable d-none d-md-block" @click="toggleDisplayPassword">
                                    <div v-if="!displayPassword">
                                        <i class="fas fa-eye"></i> Show Password
                                    </div>
                                    <div v-else>
                                        <i class="fas fa-eye-slash"></i> Hide Password
                                    </div>
                                </form-text>
                            </form-group>
                        </column>
                        <column :md="12" :lg="lgCol">
                            <form-group :valid="confirmPasswordValid">
                                <form-label>Confirm Password:</form-label>
                                <form-input name="confirm-password" :type="passwordType" v-model="confirmPassword" @input="validateForm" autocomplete="new-password" />

                                <form-text class="clickable d-block d-md-none" @click="toggleDisplayPassword">
                                    <div v-if="!displayPassword">
                                        <i class="fas fa-eye"></i> Show Password
                                    </div>
                                    <div v-else>
                                        <i class="fas fa-eye-slash"></i> Hide Password
                                    </div>
                                </form-text>
                            </form-group>
                        </column>
                    </row>

                    <form-group v-if="showRolesOptions" :valid="accountTypeValid" :feedback="accountTypeFeedback" class="mt-3">
                        <form-label class="d-block mb-2">Select Account Type(s):</form-label>

                        <form-checkbox v-if="showTravelersOption" name="is-renter" v-model="isTraveler" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                            Renter
                        </form-checkbox>

                        <form-checkbox v-if="showOwnersOption" name="is-owner" v-model="isOwner" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                            Owner
                        </form-checkbox>

                        <template v-if="showPartnersOption">
                            <form-checkbox v-model="isBroker" name="is-broker" @change="isBrokerChange" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                                Real Estate Broker
                            </form-checkbox>
                            <form-checkbox v-model="isReferral" name="is-referral" @change="isReferralChange" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                                Referral
                            </form-checkbox>
                            <form-checkbox v-model="isTravelAgent" name="is-travel-agent" @change="validateForm" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                                Travel Agent
                            </form-checkbox>
                            <form-checkbox v-model="isAffiliate" name="is-affiliate" @change="validateForm" :inline="!uiStore.isMobile" class="me-0 me-md-3">
                                Affiliate
                            </form-checkbox>
                        </template>
                    </form-group>

                    <transition name="v-slide-transition-4">
                        <row v-if="showOwnerPropertyAddress" class="mt-3">
                            <column>
                                <h6 class="mt-3">Owner Information:</h6>
                                <form-group>
                                    <form-address v-model="propertyAddress" :valid="propertyAddressValid" addressLabel="Property's Address" @input="validateForm"/>
                                </form-group>
                            </column>
                        </row>
                    </transition>
                    
                    <transition name="v-slide-transition-4">
                        <row v-if="isBroker" class="mt-3">
                            <column>
                                <h6>Brokerage Information:</h6>
                            </column>
                            <column :md="8">
                                <form-group :valid="brokerageNameValid">
                                    <form-label>Name of Brokerage:</form-label>
                                    <form-input name="name-of-brokerage" v-model="brokerageName" @input="validateForm" />
                                </form-group>
                            </column>
                            <column :md="8">
                                <form-group :valid="licenseStatesValid">
                                    <form-label>States Licensed:</form-label>
                                    <static-multi-select :options="stateOptions" v-model="licenseStates" @input="validateForm" />
                                </form-group>
                            </column>
                            <column :md="8">
                                <form-group :valid="licenseTypeValid">
                                    <form-label>Type of License:</form-label>
                                    <form-input v-model="licenseType" @input="validateForm" :options="licenseTypeOptions" />
                                </form-group>
                            </column>
                            <column :md="8">
                                <form-group :valid="licenseIdValid">
                                    <form-label>License ID:</form-label>
                                    <form-input v-model="licenseId" @input="validateForm" />
                                </form-group>
                            </column>
                            <column :md="8">
                                <form-group :valid="licenseExpValid" :feedback="licenseExpFeedback">
                                    <form-label>Expiration Date:</form-label>
                                    <form-date v-model="licenseExp" @input="validateForm" />
                                </form-group>
                            </column>
                        </row>
                    </transition>

                    <transition name="v-slide-transition-4">
                        <row v-if="isTravelAgent" class="mt-3">
                            <column>
                                <h6>Travel Agency Information:</h6>
                            </column>
                            <column :md="8">
                                <form-group :valid="agencyNameValid">
                                    <form-label>Agency Name:</form-label>
                                    <form-input v-model="agencyName" @input="validateForm" />
                                </form-group>
                            </column>
                            <column :md="8">
                                <form-group :valid="agencyIataNumberValid">
                                    <form-label>IATA Number:</form-label>
                                    <form-input v-model="agencyIataNumber" @input="validateForm" />
                                </form-group>
                            </column>
                            
                            <column :md="8">
                                <form-group :valid="agencyLicenseExpValid" :feedback="agencyLicenseExpFeedback">
                                    <form-label>Expiration Date:</form-label>
                                    <form-date v-model="agencyLicenseExp" @input="validateForm" />
                                </form-group>
                            </column>
                        </row>
                    </transition>
                </container>

                <html-content :html="terms"/>

                <form-group class="text-center">
                    <ui-button :submit="true">Create Account</ui-button>
                </form-group>

                <form-group class="text-center">
                    <ui-button size="sm" color="secondary" class="mb-3" block="sm" :underline="true" @click="() => $emit('login')">
                        Have an Account? Login
                    </ui-button>
                    <ui-button v-if="showLogout" size="sm" color="secondary" class="ms-0 ms-md-2 mb-3" block="sm" :underline="true" @click="logOut">
                        Logout
                    </ui-button>
                </form-group>
            </div>
            
        </div>

        <div class="pb-5 mb-5" v-if="!inline"><br />&nbsp;<br />&nbsp;<br />&nbsp;</div>

        <modal @close="closeThankYouModal" :open="thankYouModalOpen" v-if="showModal">
            <template #header>
                Thank You
            </template>
            <template #default>
                <p>Your account was created successfully.</p>
            </template>
            <template #buttons>
                <ui-button color="secondary" @click="thankYouModalOpen=false" :underline="true" block="sm">
                    Continue
                </ui-button>
            </template>
        </modal>
    </form>
</template>

<script type="text/javascript">
    import HtmlContent from '@/components/Content/HtmlContent.vue';
    import CommonComponents from '@/components/Common.vue';
    import Api from '@/classes/Api.js';
    import AuthGuard from '@/classes/AuthGuard.js';
    import { UseUIStore } from '@/store/UI.js';
    import { UseAuthStore } from '@/store/Auth.js';

    import StatesConfig from '@/config/states.json';
    import BrokersConfig from '@/config/brokers.json';
    import Analytics from '@/classes/Analytics.js';
    import FormOption from '@/classes/FormOption.js';

    export default {

        components: {
            HtmlContent,
            ...CommonComponents,
        },

        setup() {
            return {
                authStore: UseAuthStore(),
                uiStore: UseUIStore(),
            };
        },

        emits: ['login'],

        data() {
            return {
                stateOptions: FormOption.fromKeyValue(StatesConfig.US),
                licenseTypeOptions: FormOption.fromKeyValue(BrokersConfig.licenseTypes),

                showModal: false,
                thankYouModalOpen: true,

                displayPassword: false,

                firstName: null,
                lastName: null,
                email: null,
                phone: null,
                address: null,
                password: null,
                confirmPassword: null,
                
                isTraveler: false,
                isOwner: false,
                isBroker: false,
                isReferral: false,
                isTravelAgent: false,
                isAffiliate: false,

                // owner
                propertyAddress: null,

                // broker
                brokerageName: null,
                licenseStates: null,
                licenseType: null,
                licenseId: null,
                licenseExp: null,

                // travel agent
                agencyName: null,
                agencyIataNumber: null,
                agencyLicenseExp: null,

                firstNameValid: null,
                lastNameValid: null,
                emailValid: null,
                emailFeedback: null,
                phoneValid: null,
                addressValid: null,
                passwordValid: null,
                passwordFeedback: null,
                confirmPasswordValid: null,
                accountTypeValid: null,
                accountTypeFeedback: null,

                // owner
                propertyAddressValid: null,

                // broker
                brokerageNameValid: null,
                licenseStatesValid: null,
                licenseTypeValid: null,
                licenseIdValid: null,
                licenseExpValid: null,
                licenseExpFeedback: null,

                // travel agent
                agencyNameValid: null,
                agencyIataNumberValid: null,
                agencyLicenseExpValid: null,
                agencyLicenseExpFeedback: null,

                startValidation: false,

                // we'll use the html content to auto
                // change the terms into modals
                terms: '<p class="small text-muted mt-3">By clicking Create Account, I agree to StayMarquis <a href="/terms" target="_blank">Terms and Conditions</a>, <a href="/terms/privacy-policy" target="_blank">Privacy Policy</a>, <a href="/terms/booking-agreement" target="_blank">Booking Agreement</a>, <a href="/terms/promotion-agreement" target="_blank">Promotion Agreement</a>, <a href="/terms/service-agreement" target="_blank">Master Service Agreement</a>, <a href="/terms/concierge" target="_blank">Concierge Agreement</a>, and <a href="terms/dispute-resolution-center" target="_blank">Dispute Disclaimer</a>. I also have reviewed the Compliance page and will use the Marquis platform in a compliant manner.</p>',
            };
        },

        props: {
            inline: {
                type: Boolean,
                default: false,
            },

            quickSignup: {
                type: Boolean,
                default: false,
            },

            signupType: {
                type: String,
            },

            signupId: {
                type: [String, Number],
            },
        },

        computed: {

            lgCol() {
                if (this.quickSignup) {
                    return 12;
                }
                return 8;
            },

            boxCssClass() {
                if (!this.inline) {
                    if (this.quickSignup) {
                        return 'rounded-3 shadow vw-90 vw-lg-60 vw-xl-40 pb-4 m-auto';
                    }
                    return 'rounded-3 shadow vw-90 vw-lg-70 vw-xl-60 pb-4 m-auto';
                }
                return null;
            },

            showLogout() {
                if (this.authStore.loggedInUser) {
                    return true;
                }
                return false;
            },

            passwordType() {
                return this.displayPassword ? 'text' : 'password';
            },

            internationalNumbersTitle() {
                return 'For international numbers, start with "+" and the country code. E.g. +44 for the UK.';
            },

            showTravelersOption() {
                if (this.signupType) {
                    return false;
                }
                return true;
            },

            showOwnersOption() {
                if ((this.signupType) && (this.signupType != 'owners')) {
                    return false;
                }
                return true;
            },

            showPartnersOption() {
                if ((this.signupType) && (this.signupType != 'partners')) {
                    return false;
                }
                return true;
            },

            showRolesOptions() {
                let count = 0;
                count += this.showTravelersOption ? 1 : 0;
                count += this.showOwnersOption ? 1 : 0;
                count += this.showPartnersOption ? 1 : 0;
                
                if (count > 1) return true;
                return false;
            },

            showOwnerPropertyAddress() {
                if (this.isOwner) {
                    return true;
                }

                if ((this.signupType) && (this.signupType == 'owners')) {
                    return true;
                }
                return false;
            },
        },

        methods: {
            toggleDisplayPassword() {
                this.displayPassword = ! this.displayPassword;
            },

            isBrokerChange() {
                if (this.isBroker) {
                    this.isReferral = false;
                }
                this.validateForm();
            },

            isReferralChange() {
                if (this.isReferral) {
                    this.isBroker = false;
                }
                this.validateForm();
            },

            closeThankYouModal() {
                this.showModal = false;
                this.thankYouModalOpen = true;
            },

            validateForm() {
                if (!this.startValidation) return;

                let valid = true;

                this.firstNameValid = null;
                this.lastNameValid = null;
                this.emailValid = null;
                this.emailFeedback = null;
                this.phoneValid = null;
                this.addressValid = null;
                this.passwordValid = null;
                this.passwordFeedback = null;
                this.confirmPasswordValid = null;
                this.accountTypeValid = null;
                this.accountTypeFeedback = null;
                this.propertyAddressValid = null;
                this.brokerageNameValid = null;
                this.licenseStatesValid = null;
                this.licenseTypeValid = null;
                this.licenseIdValid = null;
                this.licenseExpValid = null;
                this.licenseExpFeedback = null;
                this.agencyNameValid = null;
                this.agencyIataNumberValid = null;
                this.agencyLicenseExpValid = null;
                this.agencyLicenseExpFeedback = null;

                if (this.address) {
                    this.address.resetValidation();
                }

                if (this.propertyAddress) {
                    this.propertyAddress.resetValidation();
                }

                if ((!this.firstName) || (this.firstName == '')) {
                    valid = false;
                    this.firstNameValid = false;
                }

                if ((!this.lastName) || (this.lastName == '')) {
                    valid = false;
                    this.lastNameValid = false;
                }

                if ((!this.email) || (this.email == '')) {
                    valid = false;
                    this.emailValid = false;
                }

                if ((!this.phone) || (this.phone == '')) {
                    valid = false;
                    this.phoneValid = false;
                }

                if (!this.quickSignup) {
                    if ((!this.address) || (!this.address.validate())) {
                        valid = false;
                        this.addressValid = false;
                    }
                }

                if ((!this.password) || (this.password == '')) {
                    valid = false;
                    this.passwordValid = false;
                }
                else if (AuthGuard.isPasswordWeak(this.password)) {
                    valid = false;
                    this.passwordValid = false;
                    this.passwordFeedback = 'This password is too weak. Please use at least '+AuthGuard.minPasswordLength+' characters and avoid common or easily guessed passwords.';
                }
                else if (this.password != this.confirmPassword) {
                    valid = false;
                    this.passwordFeedback = 'Password and Confirmation must match';
                    this.passwordValid = false;
                    this.confirmPasswordValid = false;
                }

                if (this.showRolesOptions) {
                    let selectedRoles = 0;
                    selectedRoles += this.isTraveler ? 1 : 0;
                    selectedRoles += this.isOwner ? 1 : 0;
                    selectedRoles += this.isBroker ? 1 : 0;
                    selectedRoles += this.isReferral ? 1 : 0;
                    selectedRoles += this.isTravelAgent ? 1 : 0;
                    selectedRoles += this.isAffiliate ? 1 : 0;

                    if (selectedRoles == 0) {
                        valid = false;

                        this.accountTypeValid = false;
                        this.accountTypeFeedback = 'Please select at least one account type';
                    }
                }

                if (this.showPartnersOption) {
                    if (this.isBroker) {

                        if (!this.brokerageName) {
                            valid = false;
                            this.brokerageNameValid = false;
                        }
                        if ((!this.licenseStates) || (this.licenseStates.length == 0)) {
                            valid = false;
                            this.licenseStatesValid = false;
                        }
                        if (!this.licenseType) {
                            valid = false;
                            this.licenseTypeValid = false;
                        }
                        if (!this.licenseId) {
                            valid = false;
                            this.licenseIdValid = false;
                        }
                        if (!this.licenseExp) {
                            valid = false;
                            this.licenseExpValid = false;
                        }
                        else if (this.licenseExp.time < Date.now()) {
                            valid = false;
                            this.licenseExpValid = false;
                            this.licenseExpFeedback = 'Please enter a date in the future';
                        }
                    }

                    if (this.isTravelAgent) {
                        if (!this.agencyName) {
                            valid = false;
                            this.agencyNameValid = false;
                        }
                        if (!this.agencyIataNumber) {
                            valid = false;
                            this.agencyIataNumberValid= false;
                        }
                        if (!this.agencyLicenseExp) {
                            valid = false;
                            this.agencyLicenseExpValid = false;
                        }
                        else if (this.agencyLicenseExp.time < Date.now()) {
                            valid = false;
                            this.agencyLicenseExpValid = false;
                            this.agencyLicenseExpFeedback = 'Please enter a date in the future';
                        }
                    }
                }

                if (this.showOwnerPropertyAddress) {
                    if ((!this.propertyAddress) || (!this.propertyAddress.validate())) {
                        valid = false;
                        this.propertyAddressValid = false;
                    }
                }

                return valid;
            },

            async processForm() {
                this.startValidation = true;

                if (!this.validateForm()) {
                    requestAnimationFrame(function() {

                        let firstElement = this.$refs.form.querySelector('.is-invalid');
                        if (!firstElement) firstElement = this.$refs.form;

                        let y = firstElement.getBoundingClientRect().top + window.scrollY - 60;
                        this.uiStore.scrollWindow(y);

                    }.bind(this));

                    this.uiStore.toast({
                        error: true,
                        title: 'Required Fields',
                        message: 'Please complete the highlighted fields',
                    });
                    return;
                }

                let addressObject = {};
                if (this.address) addressObject = this.address.toJSON();
                let data = {
                    quickSignup: this.quickSignup,
                    signupType: this.signupType,
                    signupId: this.signupId,
                    currentPage: location.href,

                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    phone: this.phone,
                    ...addressObject,
                    password: this.password,
                    confirmPassword: this.confirmPassword,
                    isTraveler: this.isTraveler,
                    isOwner: this.showOwnerPropertyAddress,
                    isBroker: this.isBroker,
                    isReferral: this.isReferral,
                    isTravelAgent: this.isTravelAgent,
                    isAffiliate: this.isAffiliate,
                    propertyAddress: this.propertyAddress ? this.propertyAddress.toJSON() : null,
                    brokerageName: this.brokerageName,
                    licenseStates: this.licenseStates,
                    licenseType: this.licenseType,
                    licenseId: this.licenseId,
                    licenseExp: this.licenseExp ? this.licenseExp.format('Y-m-d') : null,
                    agencyName: this.agencyName,
                    agencyIataNumber: this.agencyIataNumber,
                    agencyLicenseExp: this.agencyLicenseExp ? this.agencyLicenseExp.format('Y-m-d') : null,
                };

                let response = await Api.post('signup', data);

                if ((response.valid) && (response.results) && (!response.results.error)) {

                    Analytics.event('CompleteRegistration');

                    await AuthGuard.pullInitialData();
                    
                    if (!this.signupType) {
                        if (this.showOwnerPropertyAddress) {
                            if (response.results.ownerPropertyId) {
                                this.$router.replace('/owners?prefPropId='+response.results.ownerPropertyId);
                            }
                            else {
                                this.$router.replace('/owners');
                            }
                        }
                        else if (
                            (this.isBroker) || 
                            (this.isReferral) ||
                            (this.isTravelAgent) ||
                            (this.isAffiliate)
                        ) {
                            this.$router.replace('/partners');
                        }
                        else {
                            this.thankYouModalOpen = true;
                            this.showModal = true;
                        }
                    }
                    else if (this.signupType == 'owners') {
                        if (response.results.ownerPropertyId) {
                            this.$router.replace('/owners?prefPropId='+response.results.ownerPropertyId);
                        }
                        else {
                            this.$router.replace('/owners');
                        }
                    }
                    else if (this.signupType == 'partners') {
                        this.$router.replace('/partners');
                    }
                }
                else {
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else error = response.results.error;
                    }

                    this.uiStore.toast({
                        error: true,
                        title: 'Required Fields',
                        message: error,
                    });

                    if (response.results.required) {
                        for (let i=0; i<response.results.required.length; i++) {
                            switch (response.results.required[i]) {
                                case 'firstName':
                                    this.firstNameValid = false;
                                    break;
                                case 'lastName':
                                    this.lastNameValid = false;
                                    break;
                                case 'email':
                                    this.emailValid = false;
                                    break;
                                case 'phone':
                                    this.phoneValid = false;
                                    break;
                                case 'line1':
                                    this.addressValid = false;
                                    this.address.valid = false;
                                    this.address.line1Valid = false;
                                    break;
                                case 'city':
                                    this.addressValid = false;
                                    this.address.valid = false;
                                    this.address.cityValid = false;
                                    break;
                                case 'state':
                                    this.addressValid = false;
                                    this.address.valid = false;
                                    this.address.stateValid = false;
                                    break;
                                case 'zip':
                                    this.addressValid = false;
                                    this.address.valid = false;
                                    this.address.zipValid = false;
                                    break;
                                case 'password':
                                    this.passwordValid = false;
                                    this.passwordFeedback = 'This password is too weak. Please use at least '+AuthGuard.minPasswordLength+' characters and avoid common or easily guessed passwords.';
                                    break;
                                case 'confirmPassword':
                                    this.confirmPasswordValid = false;
                                    this.passwordValid = false;
                                    this.passwordFeedback = 'Password and Confirmation must match';
                                    break;
                            }
                        }
                    }
                }
            },

            setUserResponse(response) {
                // this request returns the user directly
                // without a response data
                // We don't have a "success", but we can have "error"
                if ((response.valid) && (response.results) && (!response.results.error)) {
                    // setting the user will update the UI
                    AuthGuard.setLoggedInUser(response.results);
                }
                else {
                    let error = 'Could not complete request';
                    if ((response.results) && (response.results.error)) {
                        if (response.results.error.message) error = response.results.error.message;
                        else error = response.results.error;
                    }
                    this.uiStore.toast({
                        error: true,
                        title: 'Error',
                        message: error,
                    });
                }
            },

            async logOut() {
                let loggedOut = await this.authStore.logout();
                if (loggedOut) {
                    this.$router.push('/');
                }
            },
        },
    };

</script>
